<template>
  <div>
    <div>
      <b-overlay show no-wrap />
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BOverlay } from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import api from "@/api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

export default {
  components: {
    BCard,
    BCardText,
    BOverlay,
  },

  created() {
    
    api
      .post("linelogin", this.$route.query, {
        headers: {},
      })
      .then((response) => {
        console.log("response get line token");
        console.log(response.data);
        var userData = JSON.parse(localStorage.getItem("userData"));
        if (userData) {
          var user_main = userData.user_main;
        } else {
          var user_main = 0;
        }
        console.log("user_main :>> ", user_main);
        console.log("******************");
        if (response.data.register == 1) {
          console.log("picture", response.data.data.picture);
          console.log("name", response.data.data.name);
          console.log("lineId", response.data.data.sub);
          console.log("email", response.data.data.email);

          this.$swal({
            title: "คุณต้องการสมัครสมาชิก?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              var email_log = response.data.data.email;
              var lineId_log = "wifi" + response.data.data.sub + "socio";
              var photo =  response.data.data.picture
              const params = {
                picture: response.data.data.picture,
                name: response.data.data.name,
                lineId: response.data.data.sub,
                email: response.data.data.email,
                user_main: user_main,
              };

              api.post("/register", params).then((response) => {
                console.log('response.data :>> ', response.data);
                const params = {
                  email: email_log,
                  password: lineId_log,
                  picture: photo,
                };

                api.post("/login", params).then((response) => {
                  console.log("response.data :>> ", response.data);
                  this.$swal({
                    icon: "success",
                    title: "สำเร็จ! กำลังเข้าสู่ระบบ",
                    customClass: {
                      confirmButton: "btn btn-success",
                    },
                  });
                  useJwt.setToken(response.data.accessToken);
                  useJwt.setRefreshToken(response.data.refreshToken);
                  localStorage.setItem(
                    "userData",
                    JSON.stringify(response.data)
                  );
                  localStorage.setItem("profile", response.data.profile);
                  this.$ability.update(response.data.ability);
                  this.$router
                    .replace(getHomeRouteForLoggedInUser("admin"))
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: `Welcome ${response.data.name}`,
                          icon: "CoffeeIcon",
                          variant: "success",
                          text: `You have successfully logged in as Admin. Now you can start to explore!`,
                        },
                      });
                    });
                });
              });
            } else if (result.dismiss === "cancel") {
              this.$swal({
                title: "Cancelled",
                text: "ยกเลิกการสมัครสมาชิก :)",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.$router.push("/login");
            }
          });

          return;
        }
        useJwt.setToken(response.data.accessToken);
        useJwt.setRefreshToken(response.data.refreshToken);
        localStorage.setItem("userData", JSON.stringify(response.data));
        this.$ability.update(response.data.ability);
        this.$router.replace(getHomeRouteForLoggedInUser("admin")).then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Welcome ${response.data.name}`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully logged in as Admin. Now you can start to explore!`,
            },
          });
        });
      });
  },
};
</script>

<style>
</style>
